<template>
  <div>
    <!-- 组队学习详情 -->
    <div class="study-show rich-text" v-html="showInfo. about">

    </div>
<!--    评价-->
    <div class="evaluate-list" ref="evaluateList" v-if="isListshow">
      <div class="my-evaluate">
        <div class="evaluate-title vertical-center">
          <div class="evaluate-title-l"></div>
          我的评价
        </div>
        <div class="van-hairline--bottom"></div>
        <div class="evaluate-content" v-for="(item,index) in evaluateList" :key="index">
          <div class=""  v-if="item.user_id == userInfo.id">
            <div class="evaluate-content-top space-between vertical-center">
              <user-info :wechat="item.wechat"></user-info>
              <div class="evaluate-content-top-time">
                {{ item.created_at }}
              </div>
            </div>
            <div class="evaluate-content-center vertical-center">
              打分
              <van-rate
                v-model="item.star"
                readonly
                size="0.6826666667rem"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
                class="star-item"
              />
            </div>
            <div class="evaluate-content-bottom">
              {{item.content}}
            </div>
          </div>
        </div>
      </div>
      <div class="other-evaluate">
        <div class="evaluate-title vertical-center">
          <div class="evaluate-title-l"></div>
          用户评价
        </div>
        <div class="van-hairline--bottom"></div>
        <div class="other-evaluate-item" v-for="(item,index) in evaluateList" :key="index" >
          <div class="" v-if="item.user_id != userInfo.id">
            <div class="evaluate-content">
              <div class="evaluate-content-top space-between vertical-center">
                <user-info :wechat="item.wechat"></user-info>
                <div class="evaluate-content-top-time">
                  {{ item.created_at }}
                </div>
              </div>
              <div class="evaluate-content-center vertical-center">
                打分
                <van-rate
                  v-model="item.star"
                  readonly
                  size="0.6826666667rem"
                  color="#ffd21e"
                  void-icon="star"
                  void-color="#eee"
                  class="star-item"
                />
              </div>
              <div class="evaluate-content-bottom">
                {{item.content}}
              </div>
            </div>
            <div class="van-hairline--bottom"></div>
          </div>

        </div>
        <div class="more" @click="more()" v-if="moreshow">加载更多</div>
      </div>
    </div>
    <div class="bot-height safety-height"></div>

    <div class="sign-up-btn-box safety-height" v-if="isShowBtn ">
      <div class="sign-up-btn flex align-center" @click="joinFun" v-if="showInfo.user_status == 0 && showInfo.can_join == 1">
        <div class="before-registration vertical-center">
          <img class="before-registration-img" src="~assets/images/user/degree-of-heat.png" alt="">
          <span >已有{{  $handle.tranNumber(showInfo.sign_number,3) }}人报名</span>
        </div>
        <div class="before-registration-text">
          <div class="sing-text vertical-center">报名</div>
        </div>
      </div>
      <div class="before-registration-ing theme-bg" :class="{'wechat-btn' : showInfo.user_status == 1}"  v-else-if="showInfo.user_status == 1">已报名</div>
      <!-- <div class="before-registration-ing theme-bg" :class="{'wechat-btn' : showInfo.user_status == 1}" @click="joinFun" v-else-if="showInfo.user_status == 1">加入群聊</div> -->
      <div class="sign-up-btn flex align-center"  v-else >
        <div class="before-registration vertical-center no-sing">
          <img class="before-registration-img" src="~assets/images/user/undegree-of-heat.png" alt="">
          <span v-if="showInfo.can_join == 0">报名人数已满</span>
          <span v-if="showInfo.can_join == 2">报名时间已过</span>
        </div>
        <div class="before-registration-text no-sing-text">
          <div class="sing-text vertical-center">已截止</div>
        </div>
      </div>
<!--      <div class="sign-up-btn theme-bg" :class="{'wechat-btn' : showInfo.user_status == 1,'ash': showInfo.can_join}" @click="joinFun" >{{ showInfo.user_status == 1 ? '加入群聊' : '报名' }}</div>-->
    </div>


    <!--报名成功提示开始-->
    <van-overlay :show=" code_show " z-index="999" >
      <div class="overlay-sign-up-con">
        <div class="overlay-sign-up">
          <div class="overlay-sign-up-head">
            <div></div>
            <div class="overlay-sign-up-head-title">
              <div class="overlay-sign-up-tip-text">{{ showInfo.user_status == 1 ? '加入群聊' : '报名成功' }}</div>
            </div>
            <img class="overlay-sign-up-img" src="~assets/images/user/overlay-cancel.png" @click="onClickHide"/>
          </div>
          <div class="overlay-scanning-body">
            <div class="overlay-scanning-tip-text">扫描下方二维加入群聊与大家一起开始学习吧！</div>
            <img class="overlay-scanning-code" :src="showInfo.wechat_code" @click="clickImg('showInfo.wechat_code')"/>
            <div class="overlay-scanning-tip-text">长按保存二维码</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--报名成功提示结束-->
    <!--每天的审核通过提示 经验值开始-->
    <van-overlay :show="isShowOverlay" z-index="999">
      <div class="overlay-examine-con">
        <div class="overlay-examine-wiper">
          <img class="overlay-examine-bg" src="~assets/images/user/overlay-sign-bg.png"/>
          <div class="overlay-examine-info">
            <div class="overlay-examine-tip">
              <div class="overlay-examine-tip-con">
                <div class="overlay-examine-tip-tit">审核通过，奖励已发放</div>
              </div>
              <div class="overlay-examine-list" :class="reward_num > 0 && experience_num > 0 ? '' : 'overlay-examine-l'">
                <div class="overlay-examine-item" v-if="reward_num > 0">
                  <div class="overlay-examine-tip-icon">积分</div>
                  <div class="overlay-examine-tip-text">+{{ $handle.tranNumber(reward_num, 3) }}</div>
                </div>
                <div class="overlay-examine-item" v-if="experience_num > 0">
                  <div class="overlay-examine-tip-icon">经验值</div>
                  <div class="overlay-examine-tip-text">+{{
                      $handle.tranNumber(experience_num, 3)
                    }}
                  </div>
                </div>
              </div>
              <div class="overlay-determine">
                <img class="overlay-determine-bg" src="~assets/images/user/overlay-determine.png"/>
                <div class="overlay-determine-text" @click="rewardClose">知道了</div>
              </div>
            </div>
          </div>
        </div>
        <img class="overlay-reward-close" src="~assets/images/user/overlay-cancel-icon.png"
             @click="rewardClose"/>
      </div>
    </van-overlay>
    <!--每天的审核通过提示 经验值结束-->
    <van-toast id="van-toast" />
  </div>
</template>

<script>
import {ImagePreview} from "vant";
import UserInfo from "components/userInfo";

export default {
  name: "sign",
  components: {UserInfo},
  data(){
    return{
      showInfo: {},
      code_show: false,
      experience_num: null,
      reward_num:null,
      isShowOverlay: false,
      isShowBtn: false,
      evaluateList:[],
      page:1,
      moreshow:false,
      isListshow:false,
    }
  },
  created() {
     if (this.userInfo.is_fresher == 1){
      this.$router.replace({path: '/'})
    }
    if(this.$route.params.id){
      this.getShow()

    }else {
      this.$router.replace({path: '/study'})
    }
    // if (this.$route.query.evaluate){
    //   this.goEvaluate()
    // }
  },
  mounted() {
    this.$nextTick(function(){

    })
  },
  watch: {
    userInfo(val, oldVal) {
      return val;
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods:{
  //  获取详情
    getShow(){
      let url = this.$api.Groups + '/' + this.$route.params.id;
      this.$http.get(url,true).then( res =>{
         if(res.data.success){
           this.showInfo = res.data.data
           /*设置meta*/
           this.$store.commit('M_TITLE', this.showInfo.name)
           if (res.data.data.experience){
             this.experience_num = res.data.data.experience.detail.amount

           }
           this.isShowBtn = true
           this.getEvaluate()
         }
      })
    },
    //获取小组评价
    getEvaluate(){
      let url = this.$api.evaluate + '?model_id=' + this.$route.params.id + '&pageSize=5&page=' + this.page;
      this.$http.get(url,true).then( res =>{
        if(res.data.success){
          this.evaluateList = res.data.data
          if(res.data.data.length){
            this.isListshow = true
            }
          if(res.data.data.length == 5){
            this.moreshow = true
            }
          if (this.$route.query.evaluate){
            setTimeout(()=>{
              this.goEvaluate()
            },200)
          }
        }
      })
    },
    //加载更多
    more(){
      this.page++
    let url = this.$api.evaluate + '?model_id=' + this.$route.params.id + '&pageSize=5&page=' + this.page;
      this.$http.get(url,true).then( res =>{
        if(res.data.success){
          this.evaluateList = this.evaluateList.concat(res.data.data)
          if(res.data.data.length < 5){
            this.moreshow = false
          }
        }
      })
    },
    // 加入二维码
    joinFun(){
      let url = this.$api.Groups
        let data = {
          id: this.$route.params.id
        }
        if(this.showInfo.user_status === 1){
          this.code_show = true;
          return
        }
        this.$http.post(url,data,true).then( res=> {
          if(res.data.success){
            this.showInfo.user_status = 1;
            if(this.experience_num != null && this.experience_num > 0){
              this.$toast('恭喜获得'+ this.experience_num + '经验值');
              setTimeout(()=> {
                this.code_show= true;
              },1500)
            }else {
              this.code_show= true;
            }
          }else {
            this.$toast(res.data.message);
            this.getShow()
          }
        })
    },
    // 点击图片
    onClickHide(){
      this.code_show = false;
      this.showInfo.user_status = 1;
    },
    // 点击图片
    clickImg(item){
      ImagePreview({
          images: [item],
      })
    },
    // 点击我知道了
    rewardClose(){
      this.isShowOverlay = false
    },
    //跳转评价
    goEvaluate(){
      if (this.evaluateList.length){
        let top = this.$refs.evaluateList.offsetTop
        this.$nextTick(function(){
          window.scrollTo(0, top)
        })
      }
    }
  },
  deactivated() {
    this.$store.commit('M_TITLE', '')
  },
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/study/show.scss";
$r: 750/16/1rem;

//审核通过提示开始
.overlay-examine-con{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  .overlay-examine-wiper{
    position: relative;
    .overlay-examine-bg{
      width: 482/$r;
      height: 670/$r;
    }
    .overlay-examine-info{
      position: absolute;
      top: 272/$r;
      width: 100%;
      padding: 0 50/$r;
      box-sizing:border-box;
    }
    .overlay-examine-tip{
      display: flex;
      flex-direction: column;
      align-items: center;
      .overlay-examine-tip-con{
        width: 100%;
      }
      .overlay-examine-tip-tit{
        width: 100%;
        margin-left: 40/$r;
        font-size: 30/$r;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 30/$r;
      }
      .overlay-examine-list{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 34/$r 0;
        .overlay-examine-item{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 180/$r;
          height: 180/$r;
          background: linear-gradient(-90deg, #F2DD97, #F5EFF0);
          border-radius: 10/$r;
          .overlay-examine-tip-icon{
            margin-bottom: 40/$r;
            font-size: 32/$r;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #98360F;
            line-height: 32/$r;
          }
          .overlay-examine-tip-text{
            font-size: 38/$r;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #98360F;
            line-height: 38/$r;
          }
        }

      }
      .overlay-examine-l{
        justify-content: center;
      }
      .overlay-determine{
        position: relative;
        width: 241/$r;
        height: 59/$r;
        .overlay-determine-bg{
          width: 241/$r;
          height: 59/$r;
        }
        .overlay-determine-text{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0 auto;
          width: 100%;
          height: 100%;
          font-size: 28/$r;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 56/$r;
          text-align: center;
        }
      }
    }
  }
  .overlay-reward-close{
    width: 69/$r;
    height: 69/$r;
    margin-top: 24/$r;
  }
}

//审核通过提示结束
</style>
<style lang="scss">
$r: 750/16/1rem;
.my-evaluate-content-top{
  .userinfo .userinfo-img{
    width: 60/$r;
    height: 60/$r;
  }
}
</style>